<script>
// @ is an alias to /src
import EventCard from "@/components/EventCard.vue";
import EventService from "@/services/EventService.js";

export default {
  name: "EventList",
  components: {
    EventCard,
  },

  data() {
    return {
      events: [],
    };
  },
  async created() {
    const response = await EventService.getEvents();
    this.events = response.data;
  },
};
</script>

<template>
  <div class="events">
    <event-card v-for="event in events" :key="event.id" :event="event" />
  </div>
</template>

<style lang="scss">
.events {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
